import { IconPencil } from "@tabler/icons-react";
import { ButtonIcon } from "~/components/_common/ButtonIcon/ButtonIcon";

import * as classes from "./EditButton.css";

export function EditButton({
  onClick,
  label,
}: {
  onClick: () => void;
  label: string;
}) {
  return (
    <ButtonIcon
      onClick={onClick}
      label={label}
      className={classes.editButton}
      filled
    >
      <IconPencil />
    </ButtonIcon>
  );
}
