import {
  SegmentedControl as MantineSegmentedControl,
  Text,
} from "@mantine/core";
import classNames from "classnames";
import { ErrorText } from "~/components/_common/form/ErrorText/ErrorText";

import * as classes from "./SegmentControl.css";

export function SegmentedControl({
  hideLabel,
  onWhite,
  ...props
}: React.ComponentProps<typeof MantineSegmentedControl> & {
  label?: string;
  hideLabel?: boolean;
  onWhite?: boolean;
  error?: string | false;
}) {
  return (
    <div className={classes.container}>
      {props.label ? (
        <Text
          component="label"
          className={classNames(classes.label, {
            [classes.labelHidden]: hideLabel,
          })}
        >
          {props.label}
        </Text>
      ) : null}
      <MantineSegmentedControl
        {...props}
        classNames={{
          root: onWhite ? classes.rootOnWhite : classes.root,
          indicator: onWhite ? classes.indicatorOnWhite : classes.indicator,
          control: classes.control,
          label: classes.segmentLabel,
          innerLabel: classes.innerLabel,
        }}
      />
      <ErrorText>{props.error}</ErrorText>
    </div>
  );
}
